import * as client_hooks from '../../../src/hooks.client.js';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48')
];

export const server_loads = [0,2,4,8];

export const dictionary = {
		"/(public)": [~9,[2],[3]],
		"/(public)/changelog": [10,[2],[3]],
		"/(public)/comparisons": [11,[2],[3]],
		"/(public)/features": [12,[2],[3]],
		"/(public)/login": [~13,[2],[3]],
		"/(public)/premium": [14,[2],[3]],
		"/(public)/vs": [~15,[2],[3]],
		"/(public)/vs/splitwise": [16,[2],[3]],
		"/(public)/vs/tricount": [17,[2],[3]],
		"/x": [~18,[4],[5]],
		"/x/account": [~19,[4],[5]],
		"/x/account/bank-connection": [~20,[4,6],[5]],
		"/x/account/bank-connection/gocardless": [~21,[4,6],[5]],
		"/x/account/bank-connection/gocardless/finish": [~23,[4,6],[5]],
		"/x/account/bank-connection/gocardless/new": [~24,[4,6],[5]],
		"/x/account/bank-connection/gocardless/new/[countryCode]": [~25,[4,6],[5]],
		"/x/account/bank-connection/gocardless/[bankId]": [~22,[4,6],[5]],
		"/x/account/bank-connection/plaid/finish": [~26,[4,6],[5]],
		"/x/account/bank-connection/plaid/new": [~27,[4,6],[5]],
		"/x/account/delete": [28,[4],[5]],
		"/x/activities": [~29,[4],[5]],
		"/x/bank-transactions": [~30,[4],[5]],
		"/x/g": [31,[4],[5]],
		"/x/g/new": [~45,[4],[5]],
		"/x/g/[groupId]": [40,[4,8],[5]],
		"/x/g/[groupId]/balance": [~41,[4,8],[5]],
		"/x/g/[groupId]/import": [~42,[4,8],[5]],
		"/x/g/(item)/[groupId]/new": [~34,[4,7],[5]],
		"/x/g/(item)/[groupId]/new/expense": [~35,[4,7],[5]],
		"/x/g/(item)/[groupId]/new/payment": [~36,[4,7],[5]],
		"/x/g/(item)/[groupId]/new/revenue": [~37,[4,7],[5]],
		"/x/g/(item)/[groupId]/recurring/[itemScheduleId]": [~38,[4],[5]],
		"/x/g/[groupId]/settings": [43,[4,8],[5]],
		"/x/g/(item)/[groupId]/settings/adduser": [~39,[4],[5]],
		"/x/g/[groupId]/stats": [~44,[4,8],[5]],
		"/x/g/(item)/[groupId]/[itemId]": [~32,[4],[5]],
		"/x/g/(item)/[groupId]/[itemId]/edit": [~33,[4],[5]],
		"/x/join/[shareId]": [~46,[4],[5]],
		"/x/premium-subscription-confirmation": [~48,[4],[5]],
		"/x/premium-subscription": [~47,[4],[5]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';